<template>
  <Dog slug="656a17c7-025c-4aa9-97aa-3e1d2e2bf30d"></Dog>
  <Dog slug="f6ab553d-0260-4669-8ba9-7b8e569caca6"></Dog>
</template>

<script>
import Dog from './components/Dog.vue'

export default {
  name: 'App',
  components: {
    Dog
  }
}
</script>
