<template>
  <n-spin :show="responseAvailable == false" :description=description>
    <n-card :title=this.result.dog.name>
      <n-progress
          type="line"
          :percentage=percentage
          v-if="responseAvailable == true"
          :height=26
      />
    </n-card>
  </n-spin>
</template>

<script>

export default {
  data() {
    return {
      result: { "dog": {} },
      responseAvailable: false
    };
  },
  name: "Dog",
  props: ['name', 'slug'],
   computed: {
     percentage() {
       return Math.round((this.result.dog.activity_value / this.result.dog.daily_goal * 100))
    },
     url() {
       return `https://app.fitbark.com/api/v2/dog/${this.slug}`
     },
     description() {
       return `Fetching! Woof...`
     }
  },
  created() {
    // fetch on init
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.responseAvailable = false;
      fetch(this.url, {
        "method": "GET",
        "headers": {
          "Authorization": "Bearer 3e86256c5e26c0bd860de052a2dfaa1a9eb64bb1f15e59dbe625ee7845456ba8",
        }
      })
      .then(response => {
        if(response.ok){
          return response.json()
        } else {
          alert("Server returned " + response.status + " : " + response.statusText);
        }
      })
      .then(response => {
        this.result = response;
        console.log(response)
        this.responseAvailable = true;
      })
      .catch(err => {
        console.log(err);
      });
    }
  }

}
</script>

<style>
body {
  padding: 100px;
}
</style>